import { useEffect, useState } from 'react'
import axios, {AxiosResponse} from 'axios'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget11,
} from '../../../_metronic/partials/widgets'
import { KTIcon } from '../../../_metronic/helpers'

const DashboardPage = () => (
  <>
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <div className='row gx-5 gx-xl-8'>
          <div className='col-xl-6'>
            <CardCountCriticalsLogs />
          </div>
          <div className='col-xl-6'>
            <CardCountLogs />
          </div>
        </div>
        <div className='row gx-5 gx-xl-8 mt-5'>
         <div className='col-xl-12'>
            <MixedWidget11
              className='card-xxl-stretch-50 mb-5 mb-xl-8'
              chartColor='primary'
              chartHeight='175px'
            />
          </div>
        </div>
      </div>

      <div className='col-xl-8'>
        <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <LogsList 
            className='card-xxl-stretch'
          />
        </div>
      </div>
    </div>
  </>
)

const CardCountCriticalsLogs = () => {
  const [count, setCount] = useState("")
  const [countLoaded, setCountLoaded] = useState(false)

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SLAPP_AGGREGATOR_API_BASE}/dashboard/logs/count/CRITICAL`)
      .then((d: AxiosResponse<any>) => d.data)
      .then((data) => {
        setCount(data);
        setCountLoaded(true);
      })
      .catch(() => {
        alert("Erreur lors du chargement du nombre de logs");
      })
  }, [])

  return (
    <div className='card bg-primary card-xxl-stretch bg-body'>
      <div className='card-body d-flex flex-column justify-content-between'>
        <KTIcon iconName='notification-on' className='text-white fs-2hx ms-n1 flex-grow-1' />
        <div className='d-flex flex-column'>
          <div className='text-white fw-bold fs-1 mb-0 mt-5'>{countLoaded && count && (count)}</div>
          <div className='text-white fw-semibold fs-6'>{!countLoaded ? 'Chargement...' : 'Critical < 72H'}</div>
        </div>
      </div>
    </div>
  )
}

const CardCountLogs = () => {
  const [count, setCount] = useState("")
  const [countLoaded, setCountLoaded] = useState(false)

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SLAPP_AGGREGATOR_API_BASE}/dashboard/logs/count/all`)
      .then((d: AxiosResponse<any>) => d.data)
      .then((data) => {
        setCount(data);
        setCountLoaded(true);
      })
      .catch(() => {
        alert("Erreur lors du chargement du nombre de logs");
      })
  }, [])

  return (
   <div className='card card-xxl-stretch bg-body'>
      <div className='card-body d-flex flex-column justify-content-between'>
        <KTIcon iconName='external-drive' className='text-primary fs-2hx ms-n1 flex-grow-1' />
        <div className='d-flex flex-column'>
          <div className='text-dark fw-bold fs-1 mb-0 mt-5'>{countLoaded && count && (count)}</div>
          <div className='text-muted fw-semibold fs-6'>{!countLoaded ? 'Chargement...' : 'Logs'}</div>
        </div>
      </div>
    </div>
  )
}

const LogsList = ({className}) => {
  const [logList, setLogList] = useState<any[]>()
  const [logListLoaded, setLogListLoaded] = useState(false)

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SLAPP_AGGREGATOR_API_BASE}/dashboard/logs/list`)
      .then((d: AxiosResponse<any>) => d.data)
      .then((data) => {
        setLogList(data);
        setLogListLoaded(true);
      })
      .catch(() => {
        alert("Erreur lors du chargement de la liste des logs");
      })
  }, [])

  const getClassByLevel = (level) => {
    switch(level) {
      case "CRITICAL":
        return "text-danger"
      case "ERROR":
      case "WARN":
        return "text-warning"
      case "DEBUG":
      case "INFO":
        return "text-info"
      default:
        return "text-muted"
    }
  }

  const formatDate = (date) => {
    let dateObj = new Date(date);
    return ((dateObj.getDay()<10?'0':'') + dateObj.getDay())+'/'+((dateObj.getMonth()<10?'0':'') + dateObj.getMonth())
  }
  const formatHours = (date) => {
    let dateObj = new Date(date);
    return ((dateObj.getHours()<10?'0':'') + dateObj.getHours())+':'+((dateObj.getMinutes()<10?'0':'') + dateObj.getMinutes());
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Les derniers logs</span>
          <span className='text-muted fw-semibold fs-7'>{logListLoaded && logList && (logList.length + ' logs')}</span>
        </h3>
      </div>
      <div className='card-body pt-5'>
        {!logListLoaded && (
          <div className='timeline-label'>Chargement...</div>
        )}
        {logListLoaded && logList && (
          <div className='timeline-label'>
            {logList.map((log) => (
              <div className='timeline-item'>
                <div className='timeline-label fw-bold text-gray-800 fs-6 text-right'>{formatDate(log.createdAt)}<br/>{formatHours(log.createdAt)}</div>
                <div className='timeline-badge'>
                  <i className={'fa fa-genderless fs-1 '+getClassByLevel(log.level)}></i>
                </div>
                <div className='fw-mormal timeline-content text-gray-800 ps-3'>
                  <span className={getClassByLevel(log.level) + ' fw-bold'}>{log.level}</span> - {log.appName}<br/>{log.content}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Tableau de bord - Logs</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
